import * as React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import i18n from "../../components/i18n";
import { Trans, withTranslation, useTranslation } from "react-i18next";
import Layout from "../../components/layout";
import ServiceSingleBoxCard from "../../components/service_single_box_card";
import FeaturedTestimonial from "../../components/featured_testimonial";

import { ServiceContactForm } from "../../components/form_service_contact";
import HubspotCta from "../../components/hubspot_cta";
import SlidebeanSlide from "../../components/slidebean_slide";
import SocialConfData from "../../conf/social.json";
import Seo from "../../components/seo";

// Images import
import img_service_header from "../../images/service-header.jpg";
import img_service_testimonials_bg from "../../images/service-testimonials.jpg";
import img_service_details from "../../images/service_details_devops_team.png";

// Testimonial
import img_photo_man from "../../images/thumb_video_javelo.jpg";
import img_video_player from "../../images/cover_video_javelo.jpg";
import NavigationHelper from "../../helpers/navigation";

// markup
const SolutionPage = (props) => {
  const [activeLanguage, setActiveLanguage] = useState();
  useEffect(() => {
    setActiveLanguage(i18n.language);
  }, [setActiveLanguage]);

  const { t } = useTranslation(["main", "forms"]);
  const ExtContactForm = withTranslation("forms")(ServiceContactForm);

  // Navigation info
  const pageInfo = {
    key: "solutions",
    item_key: "devops-team-as-a-service",
    lang: i18n.language,
  };

  //For the social share buttons
  const title = t("main:service-description_dtaas_meta_page_title");
  const url = props.location.href;
  const twitterHandle = SocialConfData.ids.twitter;
  const tags = [
    t("main:service-description_dtaas_social_twitter_tag1_txt"),
    t("main:service-description_dtaas_social_twitter_tag2_txt"),
    t("main:service-description_dtaas_social_twitter_tag3_txt"),
    t("main:service-description_dtaas_social_twitter_tag4_txt"),
  ];

  return (
    <Layout location={props.location} pageInfo={pageInfo}>
      <Helmet>
        <title>{t("main:service-description_dtaas_meta_page_title")}</title>
      </Helmet>

      <Seo
        description={t(
          "main:service-description_dtaas_meta_seo_description_txt"
        )}
        title={t("main:service-description_dtaas_meta_page_title")}
        keywords={[
          t("main:service-description_dtaas_meta_seo_keyword1_txt"),
          t("main:service-description_dtaas_meta_seo_keyword2_txt"),
          t("main:service-description_dtaas_meta_seo_keyword3_txt"),
          t("main:service-description_dtaas_meta_seo_keyword4_txt"),
        ]}
        url={props.location.href}
        image_url={`${props.location.origin}/a1_cloud_services_in_a_box.webp`}
      />

      {/* breadcumbs */}
      <div className="site-breadcumbs">
        <div className="container">
          <small>
            <a
              href={NavigationHelper.useGeneratePageURL(activeLanguage, "home")}
              title={t("main:global_header_home_menu-item-seo-title_txt")}
            >
              A1:
            </a>{" "}
            //{" "}
            <a
              href={NavigationHelper.useGeneratePageURL(
                activeLanguage,
                "services"
              )}
              title={t("main:global_header_services_menu-item-seo-title_txt")}
            >
              {t("main:services_header_breadcrumb_title")}
            </a>{" "}
            /{" "}
            <a
              href={NavigationHelper.useGeneratePageURL(
                activeLanguage,
                "services"
              )}
            >
              {t("main:services_header_devops-category_breadcrumb_title")}
            </a>{" "}
            / {t("main:service-description_dtaass_header_breadcrumb_title")}
          </small>
        </div>
      </div>
      {/* end breadcumbs */}

      <div
        className="s_detail service_header"
        style={{ backgroundImage: `url(${img_service_header})` }}
      >
        <div className="container">
          <div className="title_block">
            <h1 className="nos-service">
              <span> {t("main:services_header_cat-devops-block_title")} :</span>{" "}
              {t("main:service-description_dtaass_header_title")}
            </h1>
          </div>
          <div className="row">
            <div className="col-md-5">
              <div className="book_img">
                <img
                  className="book_img"
                  src={img_service_details}
                  alt={t("main:service-description_dtaas_main_image-alt_txt", {
                    productname: "DevOps Team as a Service",
                  })}
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="block_text desktop_view">
                <h2 className="h2_text">
                  <Trans i18nKey="main:service-description_dtaas_tagline_html">
                    <strong>Ajoutez les ressources</strong>
                    <br />
                    <strong>de toute une équipe DevOps</strong>
                    <br />
                    <strong>à la vôtre !</strong>
                  </Trans>
                </h2>
                <div className="p_text">
                  <Trans i18nKey="main:service-description_dtaas_tagline-details_html">
                    <p>
                      Profitez de notre approche One Team pour rendre vos
                      équipes plus efficaces en nous confiant les tâches liées à
                      votre infrastructure.
                    </p>
                  </Trans>
                </div>
              </div>
              <div className="block_text mobile_view">
                <h2 className="h2_text">
                  <Trans i18nKey="main:service-description_dtaas_tagline_html">
                    <strong>Ajoutez les ressources</strong>
                    <br />
                    <strong>de toute une équipe DevOps</strong>
                    <br />
                    <strong>à la vôtre !</strong>
                  </Trans>
                </h2>
                <div className="p_text">
                  <Trans i18nKey="main:service-description_dtaas_tagline-details_html">
                    <p>
                      Profitez de notre approche One Team pour rendre vos
                      équipes plus efficaces en nous confiant les tâches liées à
                      votre infrastructure.
                    </p>
                  </Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* service single header end  */}
      {/* middle section  */}
      <div className="service_detail s_detail">
        <div className="container">
          <div className="block_box">
            <ServiceSingleBoxCard
              isNew={true}
              isLimited={true}
              lang={activeLanguage}
              category="devops"
              category_label={t("main:services_main_devops-category_title")}
              service_name={t("main:services_dtaas-offer_title")}
              service_name_fragment_1={t("main:services_dtaas-offer_p1")}
              service_name_fragment_2={t("main:services_dtaas-offer_p2")}
              link_seo_title={t("main:global_main_know-more_link")}
              share_label={t("main:global_main_share_label")}
              service_description={t(
                "main:service-description_dtaas_left-block_desc_txt"
              )}
              share_title={title}
              share_url={url}
              share_twitter_handle={twitterHandle}
              share_twitter_tags={tags}
            />
          </div>
          <div className="middle_content">
            <h2>
              <span>
                {t("main:service-description_main_devops-category_title")}
              </span>{" "}
              {t("main:service-description_dtaas_title")}
            </h2>
            <SlidebeanSlide
              url={NavigationHelper.useGeneratePageURL(
                activeLanguage,
                "slide",
                "dtaas"
              )}
            />

            <h4>
              {t("main:service-description_dtaas_main_solution-section_title")}
            </h4>
            <Trans i18nKey="main:service-description_dtaas_intro_html">
              <p>
                <em>DevOps Team as a Service</em>, c'est la formule qui vous
                permet d'avoir des experts DevOps sous la main et de sereinement
                aborder votre feuille de route sans les contraintes liées au
                recrutement.
              </p>
              <p>
                Prenez une longueur d'avance sur le marché, prenons rendez-vous,
                nous trouverons ensemble la formule qui vous convient.
              </p>
            </Trans>

            <>
              <div className="button-banner">
                <HubspotCta
                  title={t("main:service-description_dtaas_cta_-tooltip_txt")}
                  btnTxt={t("main:service-description_dtaas_cta_link")}
                  ctaId="b3e6a794-c4e2-45c6-a1fa-9d7b680e85dc"
                  url="https://hubspot-cta-redirect-eu1-prod.s3.amazonaws.com/cta/redirect/26582871/c4801404-dca0-492c-817f-42f2417c712d"
                />
              </div>
            </>
          </div>
          <div className="form_block" id="contact-form">
            <h3>
              <Trans i18nKey="main:service-description_dtaas_form_header_html">
                Contactez-nous et nous apporterons du sang neuf à votre équipe
              </Trans>
            </h3>
            <ExtContactForm
              contact_source={`${pageInfo.item_key}`}
              name_label={t("forms:home_contact_name_label")}
              name_ph={t("forms:home_contact_name_placeholder")}
              fname_label={t("forms:home_contact_firstname_label")}
              fname_ph={t("forms:home_contact_firstname_placeholder")}
              company_label={t("forms:home_contact_company_label")}
              company_ph={t("forms:home_contact_company_placeholder")}
              phone_label={t("forms:home_contact_phone_label")}
              phone_ph={t("forms:home_contact_phone_placeholder")}
              email_label={t("forms:home_contact_email_label")}
              email_ph={t("forms:home_contact_email_placeholder")}
              message_label={t("forms:home_contact_message_label")}
              message_ph={t("forms:services_contact_message_placeholder")}
              terms_msg_p1={t("forms:home_contact_terms_p1")}
              terms_msg_p2={t("forms:home_contact_terms_p2")}
              send_btn={t("forms:home_contact_send_btn")}
              notif_at_least_one_error_msg={t(
                "forms:global_error_at_least_one_msg"
              )}
              notif_sending_form_msg={t("forms:global_info_sending_form_msg")}
              success_message_sent_msg={t(
                "forms:global_success_message_sent_msg"
              )}
              notif_unknown_error_msg={t("forms:global_error_unknown_msg")}
              notif_email_validation_error_msg={t(
                "forms:notif_email_validation_error_msg"
              )}
              notif_lname_validation_error_msg={t(
                "forms:notif_lname_validation_error_msg"
              )}
              notif_fname_validation_error_msg={t(
                "forms:notif_fname_validation_error_msg"
              )}
              notif_company_validation_error_msg={t(
                "forms:notif_company_validation_error_msg"
              )}
              notif_number_validation_error_msg={t(
                "forms:notif_number_validation_error_msg"
              )}
              notif_message_validation_error_msg={t(
                "forms:notif_message_validation_error_msg"
              )}
              form_required_field_msg={t(
                "forms:global_label_required_field_msg"
              )}
            />
          </div>
        </div>
      </div>
      {/* end middle section */}
      <div
        className="service_testimonial s_detail"
        style={{ backgroundImage: `url(${img_service_testimonials_bg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1 className="sec-title">{t("main:title_your_testimonials")}</h1>
            </div>
            <FeaturedTestimonial
              testimonial_headline={t("main:testimonials_main_javelo_title")}
              testimonial_description={t("main:testimonials_main_javelo_txt")}
              thumb_alt={t(
                "main:single-testimonial_main_javelo-interviewee-name_txt"
              )}
              thumb_pic={img_photo_man}
              video_preview_pic={img_video_player}
              seo_link_title={t(
                "main:single-testimonial_see-video-seo-link_txt",
                { clientname: "Javelo" }
              )}
              testimonial_url={NavigationHelper.useGeneratePageURL(
                activeLanguage,
                "client_testimonial",
                "javelo"
              )}
            />
          </div>
        </div>
      </div>
      {/* end service testimonials */}

      {/* main content end */}
    </Layout>
  );
};

export default SolutionPage;
