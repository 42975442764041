import React from "react";
// import {useState, useEffect} from "react"
import OffersHelper from "../helpers/offers";
import ShareButtons from "./share_buttons";

import img_service_cover_bg from "../images/top-bg.png";
import img_a1_cloud_small from "../images/small-logo.png";

export default function ServiceListBoxCard(props) {
  // First service
  let firstServiceClass = "";
  if (props.isFirst) firstServiceClass = " first-box";

  let serviceClass = "";

  // New service
  if (props.isNew) serviceClass = " budget_div new_div";

  // Limited offer
  if (props.isLimited) serviceClass = " budget_div limited_div";

  const offerColorsObj = OffersHelper.getOfferCardColors(props.category);

  return (
    <>
      <div
        className={`service-page-box-row${firstServiceClass} ${serviceClass} ${props.lang}`}
      >
        <div className="service-page-box-row-img">
          <div className="service-page-box-row-img-first">
            <img className="bg_img" src={img_service_cover_bg} alt="top-bg" />
            <div className="first-img-text">
              <img src={offerColorsObj.icon} alt="imh-icon" />
              <span>
                <small style={offerColorsObj.style}>
                  {props.category_label}:
                </small>
                <br />
                <b>{props.service_name_fragment_1}</b>
                <br />
                {props.service_name_fragment_2}
                {props.service_name_fragment_3 ? <br /> : ""}
                {props.service_name_fragment_3}
                {props.service_name_fragment_4 ? <br /> : ""}
                {props.service_name_fragment_4}
                {props.service_name_fragment_5 ? <br /> : ""}
                {props.service_name_fragment_5}
              </span>
            </div>
          </div>
          <div className="service-page-box-row-img-secound">
            <img
              className="bg_img"
              src={offerColorsObj.bottom}
              alt="service image"
            />
            <div className="first-img-text">
              <img src={img_a1_cloud_small} alt="small-logo" />
            </div>
          </div>
        </div>
        <div className="service-page-box-row-text">
          <h4 style={offerColorsObj.style}>{props.category_label}</h4>
          <h3>{props.service_name}</h3>
          <p>{props.service_description}</p>
          <ul className="social_links">
            <b style={{ textTransform: "uppercase" }}>{props.share_label}</b>
            <ShareButtons
              title={props.share_title}
              url={props.share_url}
              twitterHandle={props.share_twitter_handle}
              tags={props.share_twitter_tags}
            />
          </ul>
        </div>
        {/* <a href={props.link_target_url} className="btn" title={props.link_seo_title}>
            {props.link_seo_btn}
            </a> */}
      </div>
    </>
  );
}
